/**
 * Rotas disponíveis na aplicação.
 */
export enum TypesRoutes {
  // HOME
  HOME = '/',

  // DROPOUT
  UTMFORM = '/utm-form',

  // SUCCESS
  SUCCESS = '/success',

  // REQUEST_GO_BACK
  REQUEST_GO_BACK = 'requestGoBack',
}
