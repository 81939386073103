import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'

export function PathQueryParams() {
  const dispatch = useDispatch()
  const location = useLocation()

  const whitelist = ['/']

  const handler = () => {
    if (whitelist.includes(location.pathname)) {
      const searchParams = new URLSearchParams(location.search)

      setPathParams(location.pathname, searchParams)
    }
  }

  const setPathParams = (pathname: string, params: URLSearchParams) => {
    if (params.getAll.length > 0 && pathname === '/') {
      const prefixParams = {
        prefix: params.get('prefix') || undefined,
      }

      const utmCampaignParams = {
        utm_campaign: params.get('utm_campaign') || undefined,
      }

      if (prefixParams.prefix) {
        dispatch(PathParamsActions.setPrefixPathParams(prefixParams.prefix))
      }

      if (utmCampaignParams.utm_campaign) {
        dispatch(PathParamsActions.setUtmCampaignPathParams(utmCampaignParams.utm_campaign))
      }
    }
  }

  return handler
}
