import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { PathParamsTypes } from './types'

const getPrefixPathParams = (state: RootState) => state.pathparams.prefixPathParams

const setPrefixPathParams = (data: string) => action(PathParamsTypes.SET_PREFIX_PATH_PARAMS, data)

const getUtmCampaignPathParams = (state: RootState) => state.pathparams.utmCampaignParams

const setUtmCampaignPathParams = (data: string) =>
  action(PathParamsTypes.SET_UTM_CAMPAIGN_PARAMS, data)

export const PathParamsActions = {
  getPrefixPathParams,
  setPrefixPathParams,
  getUtmCampaignPathParams,
  setUtmCampaignPathParams,
}
