import { Fragment, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import HeroImg from 'src/assets/img/hero-home.png'
import { PageTitles } from 'src/common/enums/PageTitles'
import { TaggingEnum } from 'src/common/enums/TaggingEnum'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { useBasePage } from 'src/hooks/useBasePage'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathQueryParams } from 'src/routes/pathQueryParams'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { UtmFormActions } from 'src/store/ducks/utmform/actions'
import { H1, H3, PMedium, PSmall } from 'src/styles/commons'

import {
  InterUIBox,
  InterUIContainer,
  InterUIListDescription,
  InterUISeparator,
} from '@interco/inter-ui-react-lib'

import { ContentFacilities, ContentIcon, Hero } from './Home.styles'

interface IAdvantages {
  id: number
  icon: string
  head: string
  description: string
}

interface IFacilities {
  id: number
  head: string
  description: string
}

export const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const prefixPathParams = useSelector(PathParamsActions.getPrefixPathParams)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const handler = PathQueryParams()

  const typeCampaign = utmCampaign.includes('sorteio') ? 'Home Sorteio' : 'Home Guardar Dinheiro'

  useBasePage({
    navbarTitle: PageTitles.OFFER,
    showHomeBtn: true,
    hideBackNavigationBtn: true,
  })

  useEffect(() => {
    if (prefixPathParams) {
      const url = `https://${String(prefixPathParams)}.capitalizacao-webview.uatbi.com.br`
      window.open(url, '_self')
    }
  }, [prefixPathParams])

  useEffect(() => {
    handler()
  }, [])

  useEffect(() => {
    BaseBridge.requestAnalytics(TaggingEnum.CAPFAKE_ACESSO, {
      screen: typeCampaign,
      content_action: 'Dado Carregado',
      utm_campaign: utmCampaign,
      adjust_event_token: '0',
      teste: utmCampaign,
      product: 'Capitalização Fake Door',
    })
  }, [])

  const advantages: IAdvantages[] = [
    {
      id: 1,
      icon: '🤑',
      head: 'Sorteios o ano inteiro',
      description: 'Concorra a prêmios semanais, mensais e semestrais de até 2,5 milhões de reais.',
    },
    {
      id: 2,
      icon: '😎',
      head: 'Aumente seu patrimônio financeiro',
      description: 'Guarde dinheiro pagando pouco por mês para mudar de vida.',
    },
    {
      id: 3,
      icon: '💰',
      head: 'Resgate 100% do valor',
      description: 'Receba todo o valor guardado de volta ao final dos 60 meses.',
    },
  ]

  const facilities: IFacilities[] = [
    {
      id: 1,
      head: 'Escolha quanto vai guardar por mês',
      description: 'São parcelas de R$ 15,00 a R$ 700,00 que cabem no seu bolso.',
    },
    {
      id: 2,
      head: 'Contrate quantas vezes quiser',
      description: 'Quanto mais você contrata, mais chances você tem de ganhar.',
    },
    {
      id: 3,
      head: 'Disciplina para guardar dinheiro',
      description: 'São 60 meses para você criar a sua reserva financeira com tranquilidade.',
    },
    {
      id: 4,
      head: 'Antecipe seus objetivos financeiros',
      description: 'Concorra a prêmios em dinheiro ainda que já tenha sido sorteado!',
    },
  ]

  const renderSweepstakes = (
    <>
      <H1 marginBottom="8px">Concorra a prêmios em dinheiro e mude de vida</H1>
      <PMedium marginBottom="24px" scale={400}>
        São até 2,5 milhões de reais e ao final você ainda recebe seu dinheiro de volta!
      </PMedium>
    </>
  )

  const renderMoney = (
    <>
      <H1 marginBottom="8px">Guarde uma graninha e realize os seus sonhos!</H1>
      <PMedium marginBottom="24px" scale={400}>
        Conquiste a realização daquele sonho pagando pouco por mês.
      </PMedium>
    </>
  )

  const renderAdvantages = advantages.map((item) => (
    <Fragment key={item.id}>
      <InterUIListDescription
        alignAllItems="center"
        iconLeft={<ContentIcon>{item.icon}</ContentIcon>}
      >
        <PSmall marginBottom="4px" bold>
          {item.head}
        </PSmall>
        <PSmall scale={400} marginBottom="0">
          {item.description}
        </PSmall>
      </InterUIListDescription>

      {item.id !== advantages.length && <InterUISeparator height="1px" margin="16px 0" />}
    </Fragment>
  ))

  const renderFacilities = (
    <ContentFacilities>
      {facilities.map((item) => (
        <InterUIBox
          key={item.id}
          direction="column"
          align="flex-start"
          justify="flex-start"
          padding="16px"
        >
          <PSmall marginBottom="8px" bold>
            {item.head}
          </PSmall>
          <PSmall scale={400} marginBottom="0">
            {item.description}
          </PSmall>
        </InterUIBox>
      ))}
    </ContentFacilities>
  )

  const randomUtmPage = () => {
    const randomNum = Math.floor(Math.random() * 2)

    dispatch(randomNum === 1 ? UtmFormActions.adherentOffer() : UtmFormActions.adherentName())
  }

  const handleClick = (event: string) => {
    BaseBridge.requestAnalytics(TaggingEnum.CAPFAKE_FLUXO, {
      screen: typeCampaign,
      ref_type: 'Botão',
      content_action: 'Toque',
      action_id: event,
      adjust_event_token: '0',
      teste: utmCampaign,
      product: 'Capitalização Fake Door',
    })

    if (event === 'Quero conhecer') {
      randomUtmPage()
    } else {
      dispatch(UtmFormActions.dropout())
    }

    history.push(TypesRoutes.UTMFORM)
  }

  const stickyFooter = (
    <>
      <Button margin="0 24px" onClick={() => handleClick('Quero conhecer')}>
        Quero conhecer
      </Button>
      <Button
        margin="16px 24px 24px"
        variant="secondary"
        onClick={() => handleClick('Não tenho interesse')}
      >
        Não tenho interesse
      </Button>
    </>
  )

  return (
    <InterUIContainer margin="0" stickyFooter={stickyFooter}>
      <Hero>
        <img src={HeroImg} alt="Mulher afro feliz" />
      </Hero>

      <InterUIContainer margin="24px 24px 0">
        {utmCampaign.includes('dinheiro') ? renderMoney : renderSweepstakes}

        {renderAdvantages}
      </InterUIContainer>

      <InterUIContainer margin="40px 24px 48px">
        <H3 marginBottom="16px">E ainda mais facilidade para você:</H3>

        {renderFacilities}
      </InterUIContainer>
    </InterUIContainer>
  )
}
