import { action } from 'typesafe-actions'

import { RootState } from '../..'
import { UtmformTypes } from './types'

const getUtmForm = (state: RootState) => state.utm.utmForm

const adherentName = () => action(UtmformTypes.ADHERENT_NAME)

const adherentOffer = () => action(UtmformTypes.ADHERENT_OFFER)

const dropout = () => action(UtmformTypes.DROPOUT)

export const UtmFormActions = {
  getUtmForm,
  adherentName,
  adherentOffer,
  dropout,
}
