import { useEffect } from 'react'

import { useSelector } from 'react-redux'
import { PageTitles } from 'src/common/enums/PageTitles'
import { TaggingEnum } from 'src/common/enums/TaggingEnum'
import { UtmEnum } from 'src/common/enums/UtmEnum'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { useBasePage } from 'src/hooks/useBasePage'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { UtmFormActions } from 'src/store/ducks/utmform/actions'
import { ContainerFullPage, H1, PMedium, SuccessSign } from 'src/styles/commons'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUIContainer } from '@interco/inter-ui-react-lib'

import { ContentSuccess } from './Success.styles'

export const Success = () => {
  const theme = useTheme()

  const utmForm = useSelector(UtmFormActions.getUtmForm)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const successIcon = <Icons.CheckCircle width={32} height={32} color={theme.colors.base.white} />

  useBasePage({
    navbarTitle: PageTitles.EMPTY,
    showHomeBtn: true,
    hideBackNavigationBtn: true,
  })

  useEffect(() => {
    BaseBridge.requestAnalytics(TaggingEnum.CAPFAKE_SUCESSO, {
      screen: 'Comprovante Aderentes',
      ref_type: 'Sucesso',
      content_action: 'Dado Carregado',
      action_id: 'Obrigado pela sua avaliação!',
      teste: utmCampaign,
      product: 'Capitalização Fake Door',
    })
  }, [])

  const stickyFooter = (
    <>
      <Button margin="24px 0" onClick={() => BaseBridge.openDeepLink('bancointer://seguros')}>
        Voltar pra home
      </Button>
    </>
  )

  return (
    <InterUIContainer margin="0 24px" stickyFooter={stickyFooter}>
      <ContainerFullPage>
        <ContentSuccess>
          <SuccessSign>{successIcon}</SuccessSign>
          <H1 margin="24px 0 8px">Obrigado pela sua avaliação!</H1>
          <PMedium scale={400} marginBottom="0">
            {utmForm === UtmEnum.DROPOUT
              ? 'Sua opinião é muito importante para continuarmos melhorando nossos serviços.'
              : 'Essa oferta ainda não está disponível e sua contribuição foi muito importante. Em breve, você saberá em primeira mão sobre este lançamento!'}
          </PMedium>
        </ContentSuccess>
      </ContainerFullPage>
    </InterUIContainer>
  )
}
