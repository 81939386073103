/**
 * Tipos de campanha disponíveis na aplicação.
 */
export enum UtmEnum {
  MONEY = 'dinheiro',
  SWEEPSTAKES = 'guardar',
  DROPOUT = 'DROPOUT',
  ADHERENT_OFFER = 'ADHERENT_OFFER',
  ADHERENT_NAME = 'ADHERENT_NAME',
}
