import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { TaggingEnum } from 'src/common/enums/TaggingEnum'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PageDirections } from 'src/routes/pageDirections'
import { NavbarActions } from 'src/store/ducks/navbar/actions'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { UtmFormActions } from 'src/store/ducks/utmform/actions'
import { SIZE_MD } from 'src/utils/constants'
import { Icons } from 'src/utils/icons'
import { useTheme } from 'styled-components'

import { InterUINavbar } from '@interco/inter-ui-react-lib'

import { BtnRight } from './Navbar.styles'

export const Navbar = () => {
  const theme = useTheme()
  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()

  const title = useSelector(NavbarActions.getTitle)
  const toggleHomeBtn = useSelector(NavbarActions.getToggleHomeBtn)
  const backNavigation = useSelector(NavbarActions.getBackNavigationHandler)
  const toggleBackNavigationBtn = useSelector(NavbarActions.getToggleBackNavigationBtn)
  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const typeCampaign = utmCampaign.includes('sorteio') ? 'Home Sorteio' : 'Home Guardar Dinheiro'

  const isInitialPage = (path: string) => {
    const initialPage = [TypesRoutes.HOME]

    return initialPage.find((page) => page === path) !== undefined
  }

  const onClickedBack = () => {
    dispatch(NavbarActions.setDirection(PageDirections.BACKWARD))
    if (isInitialPage(location.pathname)) {
      BaseBridge.requestGoBack()
    } else {
      backNavigation()
    }
    setTimeout(() => {
      dispatch(NavbarActions.setDirection(PageDirections.FORWARD))
    }, 550)
  }

  const handleHome = () => {
    if (isInitialPage(location.pathname)) {
      BaseBridge.requestAnalytics(TaggingEnum.CAPFAKE_FLUXO, {
        screen: typeCampaign,
        ref_type: 'Botão',
        content_action: 'Toque',
        action_id: 'Pesquisa desistentes',
        adjust_event_token: '0',
        teste: utmCampaign,
        product: 'Capitalização Fake Door',
      })

      dispatch(UtmFormActions.dropout())
      history.push(TypesRoutes.UTMFORM)
    } else {
      BaseBridge.requestGoBack()
    }
  }

  const iconRightBtn = (
    <>
      <BtnRight aria-label="Botão home" data-testid="test-buttonHome" onClick={handleHome}>
        <Icons.Home
          color={
            isInitialPage(location.pathname) ? theme.colors.base.white : theme.colors.primary.A500
          }
          width={SIZE_MD}
          height={SIZE_MD}
        />
      </BtnRight>
    </>
  )

  return (
    <>
      <InterUINavbar
        style={{
          backgroundColor: isInitialPage(location.pathname) ? theme.colors.primary.A500 : undefined,
          color: isInitialPage(location.pathname) ? theme.colors.base.white : undefined,
        }}
        title={title}
        hideBack={!toggleBackNavigationBtn}
        iconRight={toggleHomeBtn ? iconRightBtn : undefined}
        onClickedBack={onClickedBack}
        data-testid="navbar-container"
      />
    </>
  )
}
