import { useState } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TaggingEnum } from 'src/common/enums/TaggingEnum'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { H2, PSmall } from 'src/styles/commons'

import { InterUIContainer, InterUIRadio } from '@interco/inter-ui-react-lib'

interface IReason {
  id: number
  reason: string
}

export const FormDropout = () => {
  const history = useHistory()

  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const [dropout, setDropout] = useState('')

  const reasonDropout: IReason[] = [
    {
      id: 1,
      reason: 'O prazo para o resgate do valor integral é muito longo',
    },
    {
      id: 2,
      reason: 'Se comprometer com pagamento regular das parcelas',
    },
    {
      id: 3,
      reason: 'Não gosto de tentar a sorte',
    },
    {
      id: 4,
      reason: 'Prefiro poupar meu dinheiro de outra forma',
    },
    {
      id: 5,
      reason: 'Não entendi como funciona',
    },
  ]

  const handleClickSend = () => {
    BaseBridge.requestAnalytics(TaggingEnum.CAPFAKE_FLUXO, {
      screen: 'Pesquisa Desistentes',
      ref_type: 'Botão',
      content_action: 'Toque',
      action_id: dropout,
      adjust_event_token: '0',
      teste: utmCampaign,
      product: 'Capitalização Fake Door',
    })

    history.push(TypesRoutes.SUCCESS)
  }

  const renderReason = reasonDropout.map((item) => (
    <InterUIRadio
      key={item.id}
      id={item.id.toString()}
      data-testid={`test-reason-${item.id}`}
      radioPosition="right"
      name="reason"
      padding="16px 0"
      value={item.reason}
      onChange={(event) => setDropout(event.target.value)}
      {...(item.id === reasonDropout.length && { border: 'none' })}
    >
      <PSmall marginBottom="0">{item.reason}</PSmall>
    </InterUIRadio>
  ))

  const stickyFooter = (
    <>
      <Button onClick={handleClickSend} disabled={!dropout}>
        Enviar
      </Button>
    </>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H2 marginBottom="16px">O que não te interessou nessa oferta?</H2>
      {renderReason}
    </InterUIContainer>
  )
}
