import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PageTitles } from 'src/common/enums/PageTitles'
import { useBasePage } from 'src/hooks/useBasePage'
import { UtmFormActions } from 'src/store/ducks/utmform/actions'

import { FormDropout } from './FormDropout/FormDropout'
import { FormName } from './FormName/FormName'
import { FormOffer } from './FormOffer/FormOffer'

export const UtmForm = () => {
  const history = useHistory()

  const utmForm = useSelector(UtmFormActions.getUtmForm)

  useBasePage({
    navbarTitle: PageTitles.UNDERSTAND,
    backNavigationHandler: history.goBack,
  })

  const chooseUtmForm: { [utmForm: string]: JSX.Element } = {
    ADHERENT_OFFER: <FormOffer />,
    ADHERENT_NAME: <FormName />,
    DROPOUT: <FormDropout />,
  }

  return <>{chooseUtmForm[utmForm]}</>
}
