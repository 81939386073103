import { useEffect, useLayoutEffect, useState } from 'react'

import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { DefaultTheme, ThemeProvider } from 'styled-components'

import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'

import { ThemesEnum } from './common/enums/ThemesEnum'
import { Navbar } from './components/Navbar/Navbar'
import BaseBridge from './config/bridge/BaseBridge'
import NewRelicUtils from './config/monitoring/NewRelicUtils'
import { ErrorView } from './pages/shared/Error/ErrorView'
import Routes from './routes'
import { ErrorActions } from './store/ducks/error/actions'

const App = () => {
  const dispatch = useDispatch()

  InterUIFonts()

  const [theme, setTheme] = useState<DefaultTheme>(InterTheme(ThemesEnum.LIGHT))

  useLayoutEffect(() => {
    chooseTheme()
  }, [])

  useEffect(() => {
    NewRelicUtils.setInitSettings()
  }, [])

  const chooseTheme = () => {
    const errorDetails = {
      title: 'Houve um erro por aqui',
      subTitle:
        'No momento, essa funcionalidade está indisponível. Por favor, tente novamente em alguns minutos.',
      disabledButton: false,
    }

    BaseBridge.getAppInfo()
      .then((response) => {
        const isDarkMode = (response as unknown as { isDarkMode: boolean })?.isDarkMode

        isDarkMode ? setTheme(InterTheme(ThemesEnum.DARK)) : setTheme(InterTheme(ThemesEnum.LIGHT))
      })
      .catch(() => dispatch(ErrorActions.show(errorDetails)))
  }

  const renderApp = BaseBridge.isBrowser() ? (
    <>Acesse usando o nosso Super App.</>
  ) : (
    theme && (
      <ThemeProvider theme={theme}>
        <GlobalUIInterStyle />
        <BrowserRouter>
          <ErrorView />
          <Navbar />
          <Routes />
        </BrowserRouter>
      </ThemeProvider>
    )
  )

  return renderApp
}

export default App
