import { Reducer } from 'redux'
import { UtmEnum } from 'src/common/enums/UtmEnum'

import { UtmformState, UtmformTypes } from './types'

const INITIAL_STATE: UtmformState = {
  utmForm: UtmEnum.ADHERENT_OFFER,
}

export const reducer: Reducer<UtmformState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UtmformTypes.ADHERENT_OFFER:
      return { ...state, utmForm: UtmEnum.ADHERENT_OFFER }
    case UtmformTypes.ADHERENT_NAME:
      return { ...state, utmForm: UtmEnum.ADHERENT_NAME }
    case UtmformTypes.DROPOUT:
      return { ...state, utmForm: UtmEnum.DROPOUT }
    default:
      return state
  }
}

export default reducer
