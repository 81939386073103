import { combineReducers } from 'redux'

import error from './error'
import loading from './loading'
import navbar from './navbar'
import pathparams from './pathparams'
import utm from './utmform'

const rootReducer = combineReducers({
  navbar,
  loading,
  error,
  pathparams,
  utm,
})

export default rootReducer
