import { useRef, useState } from 'react'

import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { TaggingEnum } from 'src/common/enums/TaggingEnum'
import { Button } from 'src/components'
import BaseBridge from 'src/config/bridge/BaseBridge'
import { TypesRoutes } from 'src/routes/mixedRoutes/types'
import { PathParamsActions } from 'src/store/ducks/pathparams/actions'
import { H2, PMedium, PSmall } from 'src/styles/commons'

import { InterUIContainer, InterUIInputGroup, InterUIRadio } from '@interco/inter-ui-react-lib'

interface IName {
  id: number
  reason: string
}

export const FormName = () => {
  const history = useHistory()

  const utmCampaign = useSelector(PathParamsActions.getUtmCampaignPathParams)

  const [isOther, setIsOther] = useState(false)
  const [textLength, setTextLength] = useState(0)
  const [name, setName] = useState('')

  const otherRef = useRef<HTMLInputElement>(null)

  const nameCampaign: IName[] = [
    {
      id: 1,
      reason: 'Capitalização',
    },
    {
      id: 2,
      reason: 'Capitalizando',
    },
    {
      id: 3,
      reason: 'Sorte Laranja',
    },
    {
      id: 4,
      reason: 'Outro',
    },
  ]

  const handleOtherChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    setTextLength(value.length)

    value ? setName(`Outro: ${value}`) : setName('')
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement

    if (value !== 'Outro') {
      setName(value)
      setTextLength(0)
      setIsOther(false)

      if (otherRef.current) {
        otherRef.current.value = ''
      }
    } else {
      setIsOther(true)
      setName('')
    }
  }

  const handleClickSend = () => {
    BaseBridge.requestAnalytics(TaggingEnum.CAPFAKE_FLUXO, {
      screen: 'Pesquisa Aderentes - Escolha nome',
      ref_type: 'Botão',
      content_action: 'Toque',
      action_id: name,
      adjust_event_token: '0',
      teste: utmCampaign,
      product: 'Capitalização Fake Door',
    })

    history.push(TypesRoutes.SUCCESS)
  }

  const renderReason = nameCampaign.map((item) => (
    <InterUIRadio
      key={item.id}
      id={item.id.toString()}
      data-testid={`test-reason-${item.id}`}
      radioPosition="right"
      name="reason"
      padding="16px 0"
      value={item.reason}
      onChange={(event) => handleChange(event)}
      {...(item.id === nameCampaign.length && { border: 'none' })}
    >
      <PSmall marginBottom="0">{item.reason}</PSmall>
    </InterUIRadio>
  ))

  const stickyFooter = (
    <>
      <Button onClick={handleClickSend} disabled={!name}>
        Enviar
      </Button>
    </>
  )

  return (
    <InterUIContainer stickyFooter={stickyFooter}>
      <H2 marginBottom="16px">Como nomearia essa oferta?</H2>
      {renderReason}

      {isOther && (
        <>
          <InterUIInputGroup>
            <input
              ref={otherRef}
              id="text"
              data-testid="test-input-other"
              name="other-name"
              placeholder="Digite aqui sua sugestão"
              autoComplete="off"
              maxLength={200}
              onChange={(event) => handleOtherChange(event)}
            />
          </InterUIInputGroup>
          <PMedium scale={400} margin="4px 0 0" textAlign="right">
            {`${textLength}/200 caracteres`}
          </PMedium>
        </>
      )}
    </InterUIContainer>
  )
}
