import { Home, Success, UtmForm } from 'src/pages/capitalization'

import { TypesRoutes as Routes } from './types'

export const mixedRoutes = [
  { route: Routes.HOME, component: Home },
  { route: Routes.UTMFORM, component: UtmForm },
  { route: Routes.SUCCESS, component: Success },
]

export * from './types'
