import styled from 'styled-components'

export const Hero = styled.figure`
  padding: 0 50px;
  background-color: ${(props) => props.theme.colors.primary.A500};

  img {
    width: 100%;
    height: auto;
  }
`
export const ContentFacilities = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`

export const ContentIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 64px;
  height: 64px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.neutral.grayscale.A100};
  font-size: 2rem;
`
